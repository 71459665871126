import { useRuntimeConfig } from "nuxt/app";
import { useUserStore } from "@/store/user";
import { useOrgStore } from "@/store/org";
import { useAuth0 } from "@auth0/auth0-vue";

export default defineNuxtRouteMiddleware(() => {
  // Skip middleware on server
  if (import.meta.server) return;

  const auth0 = useAuth0();
  const isAuthenticated = auth0.isAuthenticated.value;
  if (!isAuthenticated) return;

  const config = useRuntimeConfig();

  // init FS if we're on prod
  if (
    config.public.IS_PROD === "true" &&
    typeof FS !== "undefined" &&
    typeof FS === "function"
  ) {
    const userStore = useUserStore();
    const orgStore = useOrgStore();

    const fsOptions = {
      displayName: userStore.getUser?.name,
      email: userStore.getUser?.email,
      orgName: orgStore.getOrg?.attributes?.name,
      orgId: orgStore.getOrg?.id,
      timezone_str: orgStore.getOrg?.attributes?.time_zone,
      isSuperAdmin_bool: userStore.getUser?.role_name === "super_admin",
      userSignUpDate: userStore.getUser?.created_at,
      orgSignUpDate: orgStore.getOrg?.attributes?.created_at,
      orgEndDate: orgStore.getOrg?.attributes?.end_date,
      trialAccount: orgStore.getOrg?.attributes?.trial_account,
      salesRegion: orgStore.getOrg?.attributes?.sales_region,
      appVersion: "1.0", // TODO: this should be set properly once we setup versioning process
    };

    FS("setIdentity", {
      uid: `360_${userStore.getUser?.id}`,
      properties: fsOptions,
    });
  } else {
    // eslint-disable-next-line no-console
    console.error("FullStory not available to initialize");
  }
});
